:root {
    --colorPrincipal: #edb707;
    --colorGrisSecundario: #a1a1a1;
    --colorGRisTerciario: #676767;
    --colorFondoPrincipal: #f2f2f2;
    --colorFondoSecundario: #ebebeb;
    --alturaBarra: 4.5rem;
    --AltoCarrusel: 600px;
  }
* {
    margin: 0px;
}
a{
    text-decoration: none;
    color: var(--colorGRisTerciario);
}
ul, li{
    list-style: none;
}
h2 {
    color: var(--colorPrincipal);
    font-size: 3rem;
    @media (min-width: 576px){
        font-size: 3.5rem;
    }
}
p{
    font-size: 1.1rem;
    line-height: 2rem;
}
strong{
    color: var(--colorPrincipal);
}
.linea{
    width: 75%;
    height: 1px;
    background-color: var(--colorGrisSecundario);
    margin: 0 auto;
    margin-top: 5rem;
    margin-bottom: 5rem; 
}
.lineaCorta{
    width: 60%;
    height: 1px;
    background-color: var(--colorFondoPrincipal);
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 2rem; 
}
.contenTitle{
    text-align: center;
    font-size: 2.5rem;
}
